.footer {
  background-color: #282c34;
  padding: 20px;
  text-align: center;
  color: white;
  position: relative;
}

.footer-content {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-content a, .footer-content button {
  color: white;
  transition: transform 0.2s;
  opacity: 0; /* Inicialmente oculto para la animación */
}

.footer-content a:hover, .footer-content button:hover {
  transform: scale(1.1);
}

.footer-content svg {
  fill: white;
  width: 24px;
  height: 24px;
}

.copied-message {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: hsl(41, 94%, 45%);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  animation: fadeInOut 3s forwards;
}

.footer-text {
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  color: #666;
  opacity: 0; /* Inicialmente oculto para la animación */
}

.github, .mail, .cv {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}