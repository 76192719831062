/* Projects.css */
.projects-box {
    padding-left: 20px; /* Agrega padding lateral */
    padding-right: 20px; /* Agrega padding lateral */
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Espacio entre las tarjetas */
    justify-content: center;
    align-items: flex-start; /* Asegura que las tarjetas se alineen al inicio */
}