/* ProjectCard.css */

.project-card {
  width: 280px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Asegura que el contenido se alinee al inicio */
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s, height 0.3s;
  position: relative;
  margin-bottom: 20px; /* Añade margen inferior para el desplazamiento */
  height: 230px; /* Altura inicial de la tarjeta */
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  height: 400px; /* Altura expandida de la tarjeta */
}

.image-container {
  width: 100%;
  height: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: transform 0.2s;
}

.project-card:hover .image-container {
  transform: scale(1.1);
}

.principal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0;
  transition: opacity 0.3s, max-height 0.3s;
  max-height: 0; /* Oculta el contenedor de texto inicialmente */
  overflow: hidden;
}

.project-card:hover .text-container {
  opacity: 1;
  max-height: 200px; /* Altura del contenedor de texto cuando se expande */
}

.tech-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.icons-container img {
  width: 24px;
  height: 24px;
  transition: transform 0.2s;
  margin-top: 1em;
}

.icons-container img:hover {
  transform: scale(1.2);
}

a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

p {
  margin-bottom: 0;
  margin-top: 1em;
}	