.contact-container {
  max-width: 780px;
  margin: 0 auto;
  padding: 20px;
  padding-left: 20px; /* Ajusta el padding lateral */
  padding-right: 20px; /* Ajusta el padding lateral */
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  opacity: 0; /* Inicialmente oculto para la animación */
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: hsl(41, 94%, 45%);
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: hsl(41, 66%, 34%);
}

.loader {
  animation: spin 1s linear infinite;
  width: 24px;
  height: 24px;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.success-message {
  color: green;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.success-message,
.error-message {
  opacity: 1; /* Mostrar mensajes con transición */
}