/* App.css */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0; /* Elimina cualquier margen predeterminado del body */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.App-header {
  position: fixed;
  top: 0;
  left: 0; /* Asegura que el header esté alineado a la izquierda */
  width: 100vw; /* Usa 100vw para asegurar que el ancho sea del 100% de la ventana gráfica */
  background-color: lightgrey;
  padding: 20px;
  color: white;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  box-sizing: border-box; /* Incluye padding y borde en el ancho y alto */
}

.App-header.hide {
  transform: translateY(-100%);
}

.App-header.show {
  transform: translateY(0);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-logo {
  height: 40px;
  margin-right: 20px;
  opacity: 0; /* Inicialmente oculto para la animación */
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: nowrap; /* Asegura que los elementos no se envuelvan */
}

nav ul li {
  margin-right: 20px; /* Aumenta el margen derecho para más espacio */
  opacity: 0; /* Inicialmente oculto para la animación */
}

nav ul li:last-child {
  margin-right: 20px; /* Elimina el margen derecho del último elemento */
}

nav ul li a {
  color: grey;
  text-decoration: none;
}

.dark-mode-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1001;
  background-color: hsl(41, 94%, 45%);
  border-radius: 20px;
  width: 40px;
  height: 40px;
}

.dark-mode-toggle img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 8.499px;
}

.App-content {
  flex: 1;
  padding-top: 80px; /* Ajusta este valor según la altura de tu header */
  position: relative;
  z-index: 1;
}

.sections {
  padding: 20px;
  padding-left: 20px; /* Agrega padding lateral */
  padding-right: 20px; /* Agrega padding lateral */
  max-width: 780px; /* Establece el ancho máximo */
  margin: 0 auto; /* Centra la sección */
}

#about {
  padding-top: 60px;
}

#projects {
  background-color: #f0f0f0; /* Color de fondo blanco un tanto más gris */
}

h2 {
  color: hsl(41, 94%, 45%);
}

p {
  color: grey;
}

/* Estilos para el modo oscuro */
.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode .App-header {
  background-color: #1f1f1f;
}

.dark-mode nav ul li a {
  color: #e0e0e0;
}

.dark-mode #projects {
  background-color: #1f1f1f;
}

.dark-mode h2 {
  color: hsl(41, 94%, 45%);
}

.dark-mode p {
  color: #e0e0e0;
}

.about-section, .projects-section, .contact-section {
  opacity: 0; /* Inicialmente oculto para la animación */
}